// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/avatar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/avatar.tsx");
  import.meta.hot.lastModified = "1730048705000";
}
// REMIX HMR END

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { cn } from "~/lib/utils";
const Avatar = React.forwardRef(_c = ({
  className,
  ...props
}, ref) => <AvatarPrimitive.Root ref={ref} className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)} {...props} />);
_c2 = Avatar;
Avatar.displayName = AvatarPrimitive.Root.displayName;
const AvatarImage = React.forwardRef(_c3 = ({
  className,
  ...props
}, ref) => <AvatarPrimitive.Image ref={ref} className={cn("aspect-square h-full w-full", className)} {...props} />);
_c4 = AvatarImage;
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
const AvatarFallback = React.forwardRef(_c5 = ({
  className,
  ...props
}, ref) => <AvatarPrimitive.Fallback ref={ref} className={cn("flex h-full w-full items-center justify-center rounded-full bg-stone-100 dark:bg-stone-800", className)} {...props} />);
_c6 = AvatarFallback;
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
export { Avatar, AvatarImage, AvatarFallback };
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "Avatar$React.forwardRef");
$RefreshReg$(_c2, "Avatar");
$RefreshReg$(_c3, "AvatarImage$React.forwardRef");
$RefreshReg$(_c4, "AvatarImage");
$RefreshReg$(_c5, "AvatarFallback$React.forwardRef");
$RefreshReg$(_c6, "AvatarFallback");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;